<template>
  <div>
    <CRow class="">
      <CCol sm="6" md="4" v-for="(course, index) in courses" :key="course.id">
        <CCard>
          <CCardHeader>
            <strong>{{ course.title }}</strong>
            <div class="progress">
              <div class="progress-bar" role="progressbar" v-bind:style="getProgress(index)" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </CCardHeader>
          <CCardBody>
              <img v-bind:src="course.thumbnail" alt="" width="100%">
            {{ course.description }}
          </CCardBody>
          <CCardFooter>
              <CButton color="primary" @click="() => $router.push('/course/' + course.id)">
                  Ver curso
              </CButton>
              <div class="float-right" v-if="isCompleted(index)">
                Completado <CIcon color="success" name="cil-check" />
              </div>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Course',
  data: function () {
    return {
        courses: [],
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  mounted() {
      const token = this.$cookies.get('login_token')
      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.courses = response.data.data;
        })
  },
  methods: {
    getProgress(index) {
      if (this.courses[index].course_user) {
        let width = this.courses[index].course_user.completed / this.courses[index].course_user.total * 100;
        return {'width': width + '%'}
      }
      return {'width': '0'}
    },
    isCompleted(index) {
      if (
        this.courses[index].course_user != null && 
        this.courses[index].course_user.completed / this.courses[index].course_user.total * 100 == 100
      ) {
        return true;
      }

      return false;
    }
  }
}
</script>
